import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Thesis",
  "hideInMenu": true,
  "path": "root"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <div className="column__text">
      <p><a parentName="p" {...{
          "href": "https://sfgz.ch/"
        }}>{`Schule für Gestaltung Zürich`}</a>{` · 2019 · `}<a parentName="p" {...{
          "href": "https://creativecommons.org/licenses/by-sa/4.0/"
        }}>{`CC-BY-SA 4.0`}</a></p>
      <br />
      <br />
      <h2 {...{
        "id": "dokumentation"
      }}>{`Dokumentation`}</h2>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/posts/2020/pascale/"
          }}>{`Machine Learning im Designprozess`}</a>{` – Pascale Anderegg`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/posts/2020/marius/"
          }}>{`Gruppendynamik in der Anonymität des Internets`}</a>{` – Marius Becker`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/posts/2020/steffi/"
          }}>{`Der Pferdesport auf dem Weg zu modernen Technologien`}</a>{` – Stephanie Fuchs`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/posts/2020/marc/"
          }}>{`Crowdfunding im Gaming-Journalismus`}</a>{` – Marc Hatt`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/posts/2020/severin/"
          }}>{`Social Commerce`}</a>{` – Severin Kilchhofer`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/posts/2020/matthias/"
          }}>{`Personalisierte Werbung`}</a>{` – Matthias Koch`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/posts/2020/lars/"
          }}>{`Dr. Alexa & Co.: Medizinische Auskunft aus dem Internet`}</a>{` – Lars Mäder`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/posts/2020/nils/"
          }}>{`Inwiefern sich die Customer Journey durch den neuen Touch-Point «Sprachassistent» verändert hat`}</a>{` – Nils Mäder`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/posts/2020/tieukhe/"
          }}>{`Quantified Self`}</a>{` – Tieu Khe Mayer`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/posts/2020/natasha/"
          }}>{`Wie entsteht Vertrauen zu P2P Content Sharing Plattformen?`}</a>{` – Natasha Ruf`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/posts/2020/wolfgang/"
          }}>{`Wie Co-Creation Unternehmen und KundInnen näher zusammenbringt`}</a>{` – Wolfgang Schoeck`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/posts/2020/nicole/"
          }}>{`Einwirkung Künstlicher Intelligenz auf das Benutzererlebnis`}</a>{` – Nicole Watrinet`}</li>
      </ul>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      